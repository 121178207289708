import React, { FormEvent, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Poll as PollModel, PollState } from "../../../services/models/poll";
import { Button, Card, Form, Spinner } from "react-bootstrap";
import * as PollApi from "../../../services/network/poll-api";
import * as SectorApi from "../../../services/network/sector-api";
import { defaultPollState } from "./poll.consts";
import styles from "../../../assets/styles/ProjectInfoPage.module.css";
import stylesUtils from "../../../assets/styles/utils.module.css";

interface FillPollPageViewProps {
  pollId: string;
}

const FillPollPageView = ({ pollId }: FillPollPageViewProps) => {
  const [poll, setPoll] = useState<PollState>(defaultPollState);
  const [pollLoading, setPollLoading] = useState(true);
  const [showPollLoadingError, setShowPollLoadingError] = useState(false);
  const [sectorTitle, setSectorTitle] = useState("");

  const inputRefs = useRef<any>({});

  const refocusInput = (name: any) => {
    if (inputRefs.current[name]) {
      inputRefs.current[name].current.focus();
    }
  };

  const handleKeyDown = (
    event: React.KeyboardEvent,
    moduleIndex: number,
    valueIndex: number
  ) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent form submission
      let nextIndex = valueIndex + 1;
      while (nextIndex < poll.modules[moduleIndex].values.length) {
        if (poll.modules[moduleIndex].values[nextIndex].title === "Undefined") {
          nextIndex++;
        } else {
          break;
        }
      }
      if (nextIndex < poll.modules[moduleIndex].values.length) {
        refocusInput(
          `value-value-${moduleIndex.toString()}-${nextIndex.toString()}`
        ); // Focus on next input
      }
    }
  };

  let navigate = useNavigate();

  useEffect(() => {
    async function loadPoll() {
      try {
        setShowPollLoadingError(false);
        setPollLoading(true);
        const pollData = await PollApi.fetchPoll({ pollId: pollId });

        if (pollData) {
          setPoll(pollData);

          for (let i = 0; i < pollData.modules.length; i++) {
            for (let j = 0; j < pollData.modules[i].values.length; j++) {
              inputRefs.current[`value-value-${i.toString()}-${j.toString()}`] =
                React.createRef();
            }
          }

          const sectorData = await SectorApi.fetchSector(pollData.sector);

          if (sectorData?.name) {
            setSectorTitle(sectorData.name);
          }
        }
      } catch (error) {
        console.error(error);
        setShowPollLoadingError(true);
      } finally {
        setPollLoading(false);
      }
    }
    loadPoll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdateValue = (
    moduleIndex: number,
    valueIndex: number,
    value: string | number
  ) => {
    try {
      const updatedModules = poll.modules;
      const moduleObject = updatedModules.at(moduleIndex);
      const valueObject = moduleObject?.values.at(valueIndex);

      if (valueObject) {
        valueObject.value = value;
      } else {
        throw new Error("Value not found");
      }
      setPoll({ ...poll, modules: updatedModules });
    } catch (error) {
      console.error(error);
    }
  };

  const checkShowModule = (moduleIndex: number) => {
    try {
      const module = poll.modules[moduleIndex];
      for (let i = 0; i < module.values.length; i++) {
        if (module.values[i].title !== "Undefined") {
          return true;
        }
      }
    } catch (error) {
      console.error(error);
      alert(error);
    }
    return false;
  };

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();
    try {
      let pollResponse: PollModel;
      pollResponse = await PollApi.updatePoll(pollId, poll as PollModel);
      setPoll(pollResponse);
    } catch (error) {
      console.error(error);
      alert(error);
    } finally {
      navigate(-1);
    }
  }

  return (
    <>
      <div className={`${stylesUtils.width100} ${stylesUtils.flexLeft}`}>
        <Button className={`mb-1`} onClick={() => navigate(-1)}>
          Назад
        </Button>
      </div>
      {pollLoading && <Spinner animation="border" variant="primary" />}
      {showPollLoadingError && (
        <p>Something went wrong. Please refresh the page.</p>
      )}
      {!pollLoading && !showPollLoadingError && (
        <>
          <div>
            <h1>{poll?.title}</h1>
            <h4 className="text-center mb-3">{sectorTitle}</h4>
          </div>

          <Form onSubmit={handleSubmit}>
            {poll?.modules.map((module, moduleIndex) => (
              <div key={moduleIndex}>
                {checkShowModule(moduleIndex) && (
                  <Card className={`mb-3 ${stylesUtils.flexTextCard}`}>
                    <Card.Header>{module.title}</Card.Header>
                    <Card.Body>
                      {module.values.map((value, valueIndex) => (
                        <React.Fragment
                          key={`value-value-${moduleIndex.toString()}-${valueIndex.toString()}`}
                        >
                          {value.title !== "Undefined" && (
                            <Form.Group
                              controlId={`value-value-${moduleIndex.toString()}-${valueIndex.toString()}`}
                              className={styles.valueRow}
                            >
                              <div>{value.title}</div>
                              <Form.Control
                                className={styles.valueInput}
                                type={"number"}
                                step="0.1"
                                value={value.value}
                                ref={
                                  inputRefs.current[
                                    `value-value-${moduleIndex.toString()}-${valueIndex.toString()}`
                                  ]
                                }
                                onKeyDown={(e) =>
                                  handleKeyDown(e, moduleIndex, valueIndex)
                                }
                                onChange={(event) => {
                                  handleUpdateValue(
                                    moduleIndex,
                                    valueIndex,
                                    event.target.value
                                  );
                                }}
                                onBlur={(event) => {
                                  const value = event.target.value
                                    ? parseFloat(event.target.value)
                                    : 0;

                                  handleUpdateValue(
                                    moduleIndex,
                                    valueIndex,
                                    value
                                  );
                                }}
                              />
                            </Form.Group>
                          )}
                        </React.Fragment>
                      ))}
                    </Card.Body>
                  </Card>
                )}
              </div>
            ))}
            <Button className="mb-1" type="submit">
              Сохранить
            </Button>
          </Form>
        </>
      )}
    </>
  );
};

export default FillPollPageView;
