import { Poll } from "../models/poll";
import { fetchData } from "../../Utils/fetchData";
import authToken from "../../Utils/authToken";

interface fetchPollsProps {
  tag_id: string;
}

export async function fetchPolls(pollsRequest: fetchPollsProps): Promise<Poll[]> {
  const response = await fetchData("/poll", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken(),
    },
    body: JSON.stringify(pollsRequest),
  });
  return response.json();
}

export async function fetchPollsForSubUser(): Promise<Poll[]> {
  const response = await fetchData("/poll", {
    method: "GET",
    headers: {
      Authorization: authToken(),
    },
  });
  return response.json();
}

interface fetchPollProps {
  pollId: string;
}

export async function fetchPollFinishForSubUser(requestProps: fetchPollProps): Promise<Poll> {
  const response = await fetchData("/poll/" + requestProps.pollId + "/finish", {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Authorization: authToken(),
    },
  });
  return response.json();
}

export async function fetchPoll(requestProps: fetchPollProps): Promise<Poll> {
  const response = await fetchData("/poll/" + requestProps.pollId, {
    method: "GET",
    headers: {
      Authorization: authToken(),
    },
  });
  return response.json();
}

export interface PollInput {
  sector: string;
  tag: string;
  finished_sub_users: string[];
  allowed_sub_users: string[];
  title: string;
  description: string;
  modules: {
    id: string;
    allowed_sub_users: string[];
    title: string;
    description: string;
    values: {
      id: string;
      allowed_sub_users: string[];
      title: string;
      value: number;
    }[];
  }[];
}

export async function createPoll(poll: PollInput): Promise<Poll> {
  const response = await fetchData("/poll", {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken(),
    },
    body: JSON.stringify(poll),
  });
  return response.json();
}

export async function updatePoll(pollId: string, poll: PollInput): Promise<Poll> {
  const response = await fetchData("/poll/" + pollId, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Authorization: authToken(),
    },
    body: JSON.stringify(poll),
  });
  return response.json();
}

export async function deletePoll(pollId: string) {
  await fetchData("/poll/" + pollId, {
    method: "DELETE",
    headers: {
      Authorization: authToken(),
    },
  });
}
