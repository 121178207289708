import { StatisticSortType } from "../types/statistic";

export function getSortedStatistic(statistic: string[][], sortType: StatisticSortType): string[][] {
  const sorted = [...statistic];

  switch (sortType) {
    case "name":
      sorted.sort((a, b) => a[0].localeCompare(b[0], "ru"));
      break;
    case "rating-asc":
      sorted.sort((a, b) => {
        const aValue = parseFloat(a[1]) || 0;
        const bValue = parseFloat(b[1]) || 0;
        return aValue - bValue;
      });
      break;
    case "rating-desc":
      sorted.sort((a, b) => {
        const aValue = parseFloat(a[1]) || 0;
        const bValue = parseFloat(b[1]) || 0;
        return bValue - aValue;
      });
      break;
    default:
      break;
  }

  return sorted;
}
