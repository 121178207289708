import { Recommendation } from "../models/recommendation";
import { fetchData } from "../../Utils/fetchData";
import authToken from "../../Utils/authToken";

export async function fetchRecommendations(): Promise<Recommendation[]> {
  const response = await fetchData("/recommendation", {
    method: "GET",
    headers: {
      Authorization: authToken(),
    },
  });

  return response.json();
}

interface fetchRecommendationsBySectorProps {
  sector_id: string;
}

export async function fetchRecommendationsBySector(
  request: fetchRecommendationsBySectorProps
): Promise<Recommendation[]> {
  const response = await fetchData("/recommendation", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken(),
    },
    body: JSON.stringify(request),
  });
  return response.json();
}

export async function fetchRecommendation(recommendationId: string): Promise<Recommendation> {
  const response = await fetchData("/recommendation/" + recommendationId, {
    method: "GET",
    headers: {
      Authorization: authToken(),
    },
  });
  return response.json();
}

export interface RecommendationInputProps {
  sector: string;
  name: string;
  text: string;
  in_charge: string;
  deadline: string;
  goal: string;
  status: string;
}

export async function createRecommendation(recommendation: RecommendationInputProps): Promise<Recommendation> {
  const response = await fetchData("/recommendation", {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken(),
    },
    body: JSON.stringify(recommendation),
  });
  return response.json();
}

export async function updateRecommendation(
  recommendationId: string,
  recommendation: RecommendationInputProps
): Promise<Recommendation> {
  const response = await fetchData("/recommendation/" + recommendationId, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Authorization: authToken(),
    },
    body: JSON.stringify(recommendation),
  });
  return response.json();
}

interface updateRecommendationStatusProps {
  new_status: string;
}

export async function updateRecommendationStatus(
  recommendationId: string,
  status: updateRecommendationStatusProps
): Promise<Recommendation> {
  const response = await fetchData("/recommendation/" + recommendationId + "/status", {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Authorization: authToken(),
    },
    body: JSON.stringify(status),
  });
  return response.json();
}

export async function deleteRecommendation(recommendationId: string) {
  await fetchData("/recommendation/" + recommendationId, {
    method: "DELETE",
    headers: {
      Authorization: authToken(),
    },
  });
}
