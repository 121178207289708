import { Role } from "../models/role";
import { fetchData } from "../../Utils/fetchData";
import authToken from "../../Utils/authToken";

export async function fetchRoles(): Promise<Role[]> {
  const response = await fetchData("/user_role", {
    method: "GET",
    headers: {
      Authorization: authToken(),
    },
  });
  return response.json();
}

export async function fetchRole(roleId: string): Promise<Role> {
  const response = await fetchData("/user_role/" + roleId, {
    method: "GET",
    headers: {
      Authorization: authToken(),
    },
  });
  return response.json();
}

export interface RoleInput {
  name: string;
}

export async function createRole(role: RoleInput): Promise<Role> {
  const response = await fetchData("/user_role", {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken(),
    },
    body: JSON.stringify(role),
  });
  return response.json();
}

export async function updateRole(roleId: string, role: RoleInput): Promise<Role> {
  const response = await fetchData("/user_role/" + roleId, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Authorization: authToken(),
    },
    body: JSON.stringify(role),
  });
  return response.json();
}

export async function deleteRole(roleId: string) {
  await fetchData("/user_role/" + roleId, {
    method: "DELETE",
    headers: {
      Authorization: authToken(),
    },
  });
}
