import { RecommendationComment } from "../models/recommendation_comment";
import { fetchData } from "../../Utils/fetchData";
import authToken from "../../Utils/authToken";

export async function fetchRecommendationComments(): Promise<RecommendationComment[]> {
  const response = await fetchData("/recommendation_comment", {
    method: "GET",
    headers: {
      Authorization: authToken(),
    },
  });

  return response.json();
}

interface fetchRecommendationCommentsByDistrictProps {
  recommendation_id: string;
}

export async function fetchRecommendationCommentsByRecommendation(
  request: fetchRecommendationCommentsByDistrictProps
): Promise<RecommendationComment[]> {
  const response = await fetchData("/recommendation_comment", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken(),
    },
    body: JSON.stringify(request),
  });
  return response.json();
}

export async function fetchRecommendationComment(recommendationCommentId: string): Promise<RecommendationComment> {
  const response = await fetchData("/recommendation_comment/" + recommendationCommentId, {
    method: "GET",
    headers: {
      Authorization: authToken(),
    },
  });
  return response.json();
}

export interface RecommendationCommentInputProps {
  recommendation: string;
  text: string;
}

export async function createRecommendationComment(
  recommendationComment: RecommendationCommentInputProps
): Promise<RecommendationComment> {
  const response = await fetchData("/recommendation_comment", {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken(),
    },
    body: JSON.stringify(recommendationComment),
  });
  return response.json();
}

export async function updateRecommendationComment(
  recommendationCommentId: string,
  recommendationComment: RecommendationCommentInputProps
): Promise<RecommendationComment> {
  const response = await fetchData("/recommendation_comment/" + recommendationCommentId, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Authorization: authToken(),
    },
    body: JSON.stringify(recommendationComment),
  });
  return response.json();
}

export async function deleteRecommendationComment(recommendationCommentId: string) {
  await fetchData("/recommendation_comment/" + recommendationCommentId, {
    method: "DELETE",
    headers: {
      Authorization: authToken(),
    },
  });
}
