import { PollTag } from "../models/poll_tag";
import { fetchData } from "../../Utils/fetchData";
import authToken from "../../Utils/authToken";

interface fetchPollTagsProps {
  poll_template_id: string;
}

export async function fetchPollTags(pollTagsRequest: fetchPollTagsProps): Promise<PollTag[]> {
  const response = await fetchData("/poll_tag", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken(),
    },
    body: JSON.stringify(pollTagsRequest),
  });
  return response.json();
}

export async function fetchPollTag(pollTagId: string): Promise<PollTag> {
  const response = await fetchData("/poll_tag/" + pollTagId, {
    method: "GET",
    headers: {
      Authorization: authToken(),
    },
  });
  return response.json();
}

export interface PollTagInputProps {
  date: string;
  poll_template: string;
}

export async function createPollTag(pollTag: PollTagInputProps): Promise<PollTag> {
  const response = await fetchData("/poll_tag", {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken(),
    },
    body: JSON.stringify(pollTag),
  });
  return response.json();
}

export async function updatePollTag(pollTagId: string, pollTag: PollTagInputProps): Promise<PollTag> {
  const response = await fetchData("/poll_tag/" + pollTagId, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Authorization: authToken(),
    },
    body: JSON.stringify(pollTag),
  });
  return response.json();
}

export async function deletePollTag(pollTagId: string) {
  await fetchData("/poll_tag/" + pollTagId, {
    method: "DELETE",
    headers: {
      Authorization: authToken(),
    },
  });
}
