import { LoginCredentials } from "../models/credentials";
import { Administrator, LoginAdministrator } from "../models/administrator";
import { fetchData } from "../../Utils/fetchData";
import authToken from "../../Utils/authToken";

export async function getLoggedAdministrator(): Promise<Administrator> {
  const response = await fetchData("/user", {
    method: "GET",
    headers: {
      Authorization: authToken(),
    },
  });
  return response.json();
}

// export interface SignUpCredentials {
//     username: string,
//     email: string,
//     password: string,
// }

// export async function signUp(credentials: SignUpCredentials): Promise<Administrator> {
//     const response = await fetchData("/api/administrators/signup",
//     {
//         method: "POST",
//         headers: {
//             "Content-Type": "application/json"
//         },
//         body: JSON.stringify(credentials),
//     });
//     return response.json();
// }

export async function login(credentials: LoginCredentials): Promise<LoginAdministrator> {
  const response = await fetchData("/user/login", {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({
      username: credentials.login,
      password: credentials.password,
    }),
  });
  return response.json();
}

export async function logout() {
  // await fetchData("/api/administrators/logout", {method: "POST"});
  localStorage.removeItem("user");
}
