import { PollTemplate } from "../models/poll_template";
import { fetchData } from "../../Utils/fetchData";
import authToken from "../../Utils/authToken";

export async function fetchPollTemplates(): Promise<PollTemplate[]> {
  const response = await fetchData("/poll_template", {
    method: "GET",
    headers: {
      Authorization: authToken(),
    },
  });
  return response.json();
}

interface fetchPollTemplateProps {
  pollTemplateId: string;
}

export async function fetchPollTemplate(requestProps: fetchPollTemplateProps): Promise<PollTemplate> {
  const response = await fetchData("/poll_template/" + requestProps.pollTemplateId, {
    method: "GET",
    headers: {
      Authorization: authToken(),
    },
  });
  return response.json();
}

export interface PollTemplateInput {
  allowed_roles: string[];
  title: string;
  description: string;
  modules: {
    id: string;
    allowed_roles: string[];
    title: string;
    description: string;
    values: {
      id: string;
      allowed_roles: string[];
      title: string;
      value: number;
    }[];
  }[];
}

export async function createPollTemplate(pollTemplate: PollTemplateInput): Promise<PollTemplate> {
  console.log(pollTemplate);
  const response = await fetchData("/poll_template", {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken(),
    },
    body: JSON.stringify(pollTemplate),
  });
  return response.json();
}

export async function updatePollTemplate(
  pollTemplateId: string,
  pollTemplate: PollTemplateInput
): Promise<PollTemplate> {
  const response = await fetchData("/poll_template/" + pollTemplateId, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Authorization: authToken(),
    },
    body: JSON.stringify(pollTemplate),
  });
  return response.json();
}

export async function deletePollTemplate(pollTemplateId: string) {
  await fetchData("/poll_template/" + pollTemplateId, {
    method: "DELETE",
    headers: {
      Authorization: authToken(),
    },
  });
}
