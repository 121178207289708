import { PollState } from "../../../services/models/poll";

export const defaultPollState: PollState = {
  id: "",
  sector: "",
  tag: "",
  finished_sub_users: [],
  allowed_sub_users: [],
  title: "",
  description: "",
  modules: [
    {
      id: "",
      allowed_sub_users: [],
      title: "",
      description: "",
      values: [
        {
          id: "",
          allowed_sub_users: [],
          title: "",
          value: 0,
        },
      ],
    },
  ],
};
